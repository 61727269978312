import { RefreshOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledDatePicker,
    ControlledNumberInput,
    ControlledSelectWithMenuItems,
    FormGrid,
    Options,
    OptionsToggle,
} from '../../../shared';

import {
    FilterType,
    IReportFilter,
    IRunReportDateFilter,
    IRunReportFilter,
    IRunReportListFilter,
    IRunReportNumberFilter,
} from '../../models';
import { allRequiredFiltersFilledInCheck } from '../../utils/all-required-filters-filled-in-check.util';

interface Props {
    availableFilters: IReportFilter[];
    onChange: (filters: IRunReportFilter[]) => void;
    filters: IRunReportFilter[];
}

type FilterValue = string[] | { startDate?: string; endDate?: string } | { min?: string; max?: string };
type DashboardFilterForm = Record<string, FilterValue>;

const DOT_CHARACTER = '__DOT__';

export const ReportFilter: FC<Props> = ({ availableFilters, filters, onChange }) => {
    const { t } = useTranslation();
    const form = useForm();
    const hasFilterFormChanged = useHasFilterFormChanged(form, filters);
    const areAllRequiredFiltersFilledIn = useHasAllRequiredFiltersFilledIn(form, availableFilters);

    const [options, setOptions] = useState<Options>({});

    const onFilterChange = useCallback(
        (formValues: DashboardFilterForm) => {
            onChange(mapFormValuesToAppliedFilters(formValues));
        },
        [onChange],
    );

    useEffect(() => {
        const inactiveFilters: string[] = [];
        Object.entries(options)?.forEach(([, option]) => {
            const filter = availableFilters?.find((filter) => filter.label === option.label);

            if (!option.active && filter) {
                const { type, column } = filter;
                if (type === FilterType.SELECT) return inactiveFilters.push(column);
                if (type === FilterType.DATE) return inactiveFilters.push(`${column}.startDate`, `${column}.endDate`);
                if (type === FilterType.NUMBER) return inactiveFilters.push(`${column}.min`, `${column}.max`);
            }
        });

        if (inactiveFilters?.length) inactiveFilters?.forEach((field) => form.resetField(field));
    }, [options, availableFilters, form]);

    useEffect(() => {
        form.reset({});
        filters.forEach((filter) => {
            const fieldName = getFieldNameFromColumn(filter.column);
            if (filter.type === FilterType.NUMBER) {
                form.setValue(`${fieldName}.min`, filter.min?.toString());
                form.setValue(`${fieldName}.max`, filter.max?.toString());
            }
            if (filter.type === FilterType.DATE) {
                form.setValue(`${fieldName}.startDate`, filter.start);
                form.setValue(`${fieldName}.endDate`, filter.end);
            }
            if (filter.type === FilterType.SELECT) {
                form.setValue(fieldName, filter.values);
            }
        });
    }, [filters, form.setValue]);

    useEffect(() => {
        setOptions({
            filter: { type: 'title', label: t('filter') },
            ...mapAvailableFilterToFilterOptions(availableFilters),
        });
    }, [availableFilters]);

    const hasOptionalFilter = availableFilters?.some((filter) => !filter.required);

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {hasOptionalFilter && <OptionsToggle options={options} onChange={setOptions} />}
            <FormGrid containerProps={{ spacing: 1, py: 1 }} sx={{ '&.MuiGrid-item': { pr: 1 } }}>
                {availableFilters.map((filter) => {
                    if (!options[filter.label]?.active && !filter.required) return <Fragment key={filter.label} />;

                    const fieldName = getFieldNameFromColumn(filter.column);
                    return (
                        <Fragment key={filter.label}>
                            {filter.type === FilterType.NUMBER && (
                                <Stack direction="row" spacing={1}>
                                    <ControlledNumberInput
                                        form={form}
                                        name={`${fieldName}.min`}
                                        label={`${t('min')} ${filter.label.toLowerCase()}`}
                                        size="small"
                                        decimalScale={0}
                                        className="filter"
                                    />
                                    <ControlledNumberInput
                                        form={form}
                                        name={`${fieldName}.max`}
                                        label={`${t('max')} ${filter.label.toLowerCase()}`}
                                        size="small"
                                        decimalScale={0}
                                        className="filter"
                                    />
                                </Stack>
                            )}
                            {filter.type === FilterType.DATE && (
                                <Stack direction="row" spacing={1}>
                                    <ControlledDatePicker
                                        name={`${fieldName}.startDate`}
                                        label={t('startDate')}
                                        control={form.control}
                                        size="small"
                                        className="filter"
                                    />
                                    <ControlledDatePicker
                                        name={`${fieldName}.endDate`}
                                        label={t('endDate')}
                                        control={form.control}
                                        size="small"
                                        className="filter"
                                    />
                                </Stack>
                            )}
                            {filter.type === FilterType.SELECT && (
                                <ControlledSelectWithMenuItems
                                    name={fieldName}
                                    label={filter.label}
                                    form={form}
                                    options={filter.values || []}
                                />
                            )}
                        </Fragment>
                    );
                })}
            </FormGrid>
            {hasFilterFormChanged && areAllRequiredFiltersFilledIn && (
                <IconButton onClick={form.handleSubmit(onFilterChange)}>
                    <RefreshOutlined color="primary" />
                </IconButton>
            )}
        </Stack>
    );
};

function useHasAllRequiredFiltersFilledIn(
    form: UseFormReturn<DashboardFilterForm>,
    availableFilters: IReportFilter[],
): boolean {
    const formValues = form.watch();

    return useMemo(() => {
        const filters = mapFormValuesToAppliedFilters(formValues);

        return allRequiredFiltersFilledInCheck(availableFilters, filters);
    }, [formValues, availableFilters]);
}

function useHasFilterFormChanged(
    form: UseFormReturn<DashboardFilterForm>,
    appliedFilters: IRunReportFilter[],
): boolean {
    const formValues = form.watch();

    return useMemo(() => {
        const newlyAppliedFilters = mapFormValuesToAppliedFilters(formValues);
        return JSON.stringify(newlyAppliedFilters) !== JSON.stringify(appliedFilters);
    }, [appliedFilters, formValues]);
}

function mapFormValuesToAppliedFilters(filterForm: DashboardFilterForm): IRunReportFilter[] {
    return Object.entries(filterForm)
        .map(([fieldName, filterValue]) => mapFilter(getColumnFromFieldName(fieldName), filterValue))
        .filter((appliedFilter) => !!appliedFilter) as IRunReportFilter[];
}

function mapFilter(
    column: string,
    value: FilterValue,
): IRunReportNumberFilter | IRunReportDateFilter | IRunReportListFilter | undefined {
    if (isArrayValue(value)) return { type: FilterType.SELECT, column, values: value };
    if (isDateValue(value)) return { type: FilterType.DATE, column, start: value.startDate, end: value.endDate };
    if (isNumberValue(value)) {
        return {
            type: FilterType.NUMBER,
            column,
            ...(value.min?.toString() !== '' && { min: Number(value.min) }),
            ...(value.max?.toString() !== '' && { max: Number(value.max) }),
        };
    }
}

function isArrayValue(value: FilterValue): value is string[] {
    return Array.isArray(value) && value.length > 0;
}

function isDateValue(value: FilterValue): value is { startDate: string; endDate: string } {
    const castedValue = value as { startDate?: string; endDate?: string };
    return Boolean(castedValue?.startDate && castedValue?.endDate);
}

function isNumberValue(value: FilterValue): value is { min: string; max: string } {
    const castedValue = value as { min?: string; max?: string };
    const min = castedValue?.min !== '' && Number(castedValue?.min);
    const max = castedValue?.max !== '' && Number(castedValue?.max);
    return Boolean(min || max);
}

function mapAvailableFilterToFilterOptions(availableFilters: IReportFilter[]) {
    return availableFilters
        .filter(({ required }) => !required)
        .reduce((filters, { label }) => ({ ...filters, [label]: { active: false, label: label } }), {});
}

function getFieldNameFromColumn(column: string): string {
    return column.replace('.', DOT_CHARACTER);
}

function getColumnFromFieldName(fieldName: string): string {
    return fieldName.replace(DOT_CHARACTER, '.');
}
