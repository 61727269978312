import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import React, { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = ComponentProps<typeof TextField> & { setValue: (value: string) => void };

export const SearchInput: FC<Props> = ({ value, setValue, ...props }) => {
    const { t } = useTranslation();
    const isAntwerpTheme = window.carity.environment.theme.name === 'antwerp';
    const themeProps = isAntwerpTheme ? { placeholder: t('search') } : { label: t('search') };
    return (
        <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            size="small"
            data-testid="search-input"
            slotProps={{
                input: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                },
            }}
            {...themeProps}
            {...props}
        />
    );
};
