import { Components, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

export function getComponents({ palette, zIndex }: Theme, options?: Components): Components {
    return {
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    borderTop: `solid 1px ${grey['300']}`,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderBottom: `solid 1px ${grey['300']}`,
                    backgroundColor: 'white',
                    color: palette.primary.main,
                    background: '#FFF',
                    position: 'fixed',
                    marginBottom: '60px',
                    zIndex: zIndex.drawer + 1,
                },
            },
            defaultProps: {
                elevation: 0,
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    width: '248px',
                    zIndex: 1,
                    flexShrink: 0,
                },
                paper: {
                    width: '248px',
                    paddingTop: 15,
                    boxSizing: 'border-box',
                    '.MuiList-root': {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: { minHeight: 55, '@media (min-width: 0px)': { minHeight: 55 } },
            },
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiTextField: {
            defaultProps: {
                slotProps: {
                    inputLabel: { shrink: true },
                },
                fullWidth: true,
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    background: '#FAFAFA',
                    justifyContent: 'flex-start',
                    padding: '16px 24px',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    paddingBottom: 0,
                },
                title: {
                    fontSize: 18,
                    fontWeight: 'bolder',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingBottom: '16px',
                    },
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: { fontVariant: 'all-small-caps' },
            },
        },
        ...options,
    };
}
