import { PaletteOptions } from '@mui/material';
import { transparentize } from 'polished';

export function getPalette(color: string, options?: PaletteOptions): PaletteOptions {
    return {
        primary: {
            main: color,
            shade: transparentize(0.85, color),
            ...options?.primary,
        },
        secondary: {
            main: color,
            shade: transparentize(0.85, color),
            ...options?.secondary,
        },
        background: {
            default: '#FAFAFA',
            paper: '#FFF',
            ...options?.background,
        },
        error: {
            main: '#AA1409',
            shade: '#AA14094D',
            ...options?.error,
        },
        info: {
            main: '#0064B4',
            shade: transparentize(0.85, '#0064B4'),
            ...options?.info,
        },
        ...options,
    };
}
