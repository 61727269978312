import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardTwoTone } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ControlledInputWithMask, Widget } from '../../../shared';
import { useRegistrationNationSecurityNumberSchema } from '../../validators';

export const StartRegistrationNationalSecurityNumber: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const form = useForm<{ nationalSecurityNumber: string }>({
        resolver: yupResolver(useRegistrationNationSecurityNumberSchema()),
    });

    const onSubmit = useCallback(
        (data: { nationalSecurityNumber: string }) => {
            navigate(`/registration/info?nationalSecurityNumber=${data.nationalSecurityNumber}`);
        },
        [navigate],
    );

    return (
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
            <Widget sx={{ alignItems: 'center', textAlign: 'center', p: 4 }}>
                <KeyboardTwoTone sx={{ fontSize: 80 }} color="primary" />
                <Typography variant="h6">{t('nationalSecurityNumberLabel')}</Typography>
                <Box sx={{ width: 350, alignSelf: 'center', mt: 1, mb: 2 }}>
                    <ControlledInputWithMask
                        name="nationalSecurityNumber"
                        control={form.control}
                        mask="**.**.**-***.**"
                        placeholder={'XX.XX.XX-XXX.XX'}
                    />
                </Box>
                <Box sx={{ width: 350, alignSelf: 'center' }}>
                    <Button type="submit" variant="contained" size="large" fullWidth>
                        {t('startRegistration')}
                    </Button>
                </Box>
            </Widget>
        </form>
    );
};
