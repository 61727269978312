import { createTheme } from '@mui/material';
import { enUS, nlNL } from '@mui/material/locale';
import { useTranslation } from 'react-i18next';
import { createAntwerpTheme } from '../themes/antwerp.theme';
import { createBaseTheme } from '../themes/base.theme';

export const useTheme = () => {
    const { i18n } = useTranslation();
    const theme = window.carity.environment.theme;

    const locale = i18n.language === 'nl' ? nlNL : enUS;

    switch (theme.name) {
        case 'antwerp':
            return createTheme(createAntwerpTheme(theme), locale);
        default:
            return createTheme(createBaseTheme(theme), locale);
    }
};
