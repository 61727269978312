import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    ConfirmModal,
    IOrganisation,
    IOrganisationType,
    ISupportType,
    Page,
    SortOrder,
    Table,
    useGetOrganisations,
    useQueryParamsContext,
} from '../../../shared';
import { SearchInput } from '../../../shared/components/search/search.component';
import { useDeleteOrganisation } from '../../hooks';

export function OrganisationsPage() {
    const { t } = useTranslation();
    const {
        queryParams: { page, pageSize, sort, search },
        debouncedQueryParams,
        setSearch,
        setPage,
        setPageSize,
        setSort,
    } = useQueryParamsContext('organisations', {
        sort: [{ field: 'name', sort: 'asc' }],
    });

    const { isPending, data: organisations } = useGetOrganisations({
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedQueryParams.search,
    });

    const { mutate: deleteOrganisation } = useDeleteOrganisation();

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('nameLabel'), minWidth: 150, flex: 1 },
        {
            field: 'type',
            headerName: t('typeLabel'),
            minWidth: 150,
            flex: 1,
            valueFormatter: (value: IOrganisationType) => value.name,
        },
        {
            field: 'supportTypes',
            headerName: t('supportTypes'),
            minWidth: 200,
            flex: 2,
            valueFormatter: (value: ISupportType[]) => value.map(({ name }) => name).join(', '),
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams<IOrganisation>) => (
                <>
                    <IconButton component={Link} to={`/admin/organisations/edit/${id}`} size="large">
                        <EditIcon />
                    </IconButton>
                    <ConfirmModal
                        onConfirm={() => deleteOrganisation(id)}
                        button={
                            <IconButton aria-label="delete" size="large">
                                <DeleteOutlineIcon />
                            </IconButton>
                        }
                        title={t('organisationDeleteWarningTitle')}
                        text={t('organisationDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('organisations')}
            actions={[
                <SearchInput value={search} setValue={setSearch} />,
                <Button
                    component={Link}
                    to="/admin/organisations/edit"
                    color="primary"
                    variant="contained"
                    data-testid="newOrganisation"
                >
                    {t('newOrganisation')}
                </Button>,
            ]}
        >
            <Table
                rows={organisations?.data || []}
                columns={columns}
                rowCount={organisations?.pagination?.size || 0}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                loading={isPending}
                sortModel={sort}
                onSortChange={setSort}
            />
        </Page>
    );
}
