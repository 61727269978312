import { PersonSearchTwoTone, QrCodeTwoTone } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import scanner from 'onscan.js';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page, PermissionKeys, useHasPermission, Widget } from '../../../shared';
import {
    SearchSupportCaseModal,
    StartRegistrationNationalSecurityNumber,
    StartRegistrationQrCode,
} from '../../components';

export const StartRegistrationPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showSupportCaseSearch, setShowSupportCaseSearch] = useState(false);
    const { hasPermission } = useHasPermission();
    const supportCaseReadPermission = hasPermission(PermissionKeys.SUPPORT_CASES_READ);

    const onScan = useCallback(
        (scanned: string) => {
            let parsedCode = scanned?.replace(/-/g, '');
            if (parsedCode?.startsWith('SDVH') && parsedCode.length > 8) parsedCode = parsedCode.replace('SDVH', '');
            const formattedCode = [parsedCode?.slice(0, 4), parsedCode?.slice(4)].join('-');
            setTimeout(() => {
                navigate(`/registration/info?code=${formattedCode}`);
            });
        },
        [navigate],
    );

    useEffect(() => {
        scanner.attachTo(document, { onScan });
        return () => scanner.detachFrom(document);
    }, [onScan]);

    return (
        <Page>
            <Grid container rowSpacing={3} spacing={3} alignItems="stretch">
                <Grid item sm={12} md={6}>
                    <Widget sx={{ alignItems: 'center', textAlign: 'center', p: 4 }}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                        >
                            <QrCodeTwoTone sx={{ fontSize: 80 }} color="primary" />
                            <Typography variant="h6">{t('scanCode')}</Typography>
                            {t('scanDescription')}
                        </Box>
                    </Widget>
                </Grid>
                <Grid item sm={12} md={6}>
                    <StartRegistrationQrCode />
                </Grid>
                <Grid item sm={12} md={6}>
                    <StartRegistrationNationalSecurityNumber />
                </Grid>
                <Grid item sm={12} md={6}>
                    <Widget sx={{ alignItems: 'center', textAlign: 'center', p: 4 }}>
                        <PersonSearchTwoTone sx={{ fontSize: 80 }} color="primary" />
                        <Typography variant="h6">{t('searchSupportCase')}</Typography>
                        {t('searchSupportCaseDescription')}
                        <Box sx={{ mt: 1, width: 350, alignSelf: 'center' }}>
                            <Button
                                data-testid="search-support-case"
                                variant="outlined"
                                size="large"
                                fullWidth
                                onClick={() => setShowSupportCaseSearch(true)}
                                disabled={!supportCaseReadPermission}
                            >
                                {t('search')}
                            </Button>
                        </Box>
                    </Widget>
                </Grid>
            </Grid>

            {supportCaseReadPermission && (
                <SearchSupportCaseModal
                    open={showSupportCaseSearch}
                    handleClose={() => setShowSupportCaseSearch(false)}
                />
            )}
        </Page>
    );
};
