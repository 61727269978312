import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHasPermission } from '../../../shared';
import { getFirstNavigationTab } from '../../utils';

export const AppTitle: FC = () => {
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();

    return (
        <Box component="div" sx={{ display: { xs: 'none', xl: 'block' } }}>
            <Typography onClick={() => navigate(getFirstNavigationTab(hasPermission))} variant="h1">
                <Box sx={{ marginRight: '12px' }}>
                    <img src={window.carity.environment.theme.logo} style={{ height: '100%' }} alt="Carity" />
                </Box>
                <Typography fontWeight="normal">
                    carity<strong>support</strong>
                </Typography>
            </Typography>
        </Box>
    );
};
