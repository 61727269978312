import { TextField } from '@mui/material';
import { ComponentProps, FC, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

type Props = ComponentProps<typeof TextField> & {
    name: string;
    mask: string;
    control: Control<any>;
    disabled?: boolean;
};

export const ControlledInputWithMask: FC<Props> = ({ name, label, mask, control, disabled, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ReactInputMask mask={mask} value={value} onChange={onChange} disabled={disabled}>
                    {
                        (() => (
                            <TextField
                                label={label}
                                error={!!error}
                                helperText={error ? error.message : null}
                                id={`${name}-field`}
                                disabled={disabled}
                                {...props}
                            />
                        )) as unknown as ReactNode
                    }
                </ReactInputMask>
            )}
        />
    );
};
