import { Button } from '@mui/material';
import * as qs from 'qs';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IOrganisation, Page, PermissionKeys, useHasPermission, useSelectedOrganisation } from '../../../shared';
import { ConfirmSupportCase, NoSupportCaseFound, Registration, SelectSupportCase } from '../../components';
import { useSupportCaseInfo } from '../../hooks';
import { ISupportCaseInfo } from '../../models';

export const InfoSupportCasePage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { caseId, code, nationalSecurityNumber } = useSupportCaseQueryParams();
    const { organisation } = useSelectedOrganisation();
    const { data: supportCases, isError } = useSupportCaseInfo(caseId, code, nationalSecurityNumber, organisation?.id);

    const [selectedSupportCase, setSelectedSupportCase] = useState<ISupportCaseInfo | null | undefined>(undefined);
    const hasFullAccess = useHasFullAccess(selectedSupportCase, organisation);

    const showRegistrationScreen = selectedSupportCase && !selectedSupportCase.isUnconfirmed;
    const showConfirmSupportCaseScreen = selectedSupportCase?.isUnconfirmed && organisation;
    const showSelectSupportCaseScreen = selectedSupportCase === null && supportCases && organisation;

    const goBack = () => navigate('/registration');
    const goToDetail = () => navigate(`/support/support-cases/${selectedSupportCase?.supportCaseId}/detail`);

    useAutoSelectFirstSupportCase(supportCases, organisation, setSelectedSupportCase);

    return (
        <Page
            title={t(showConfirmSupportCaseScreen ? 'confirmSupportCase' : 'confirmRegistration')}
            onBack={goBack}
            actions={
                hasFullAccess && (
                    <Button variant="outlined" onClick={goToDetail}>
                        {t('goToDetailSupportCase')}
                    </Button>
                )
            }
        >
            {showRegistrationScreen && <Registration supportCase={selectedSupportCase} />}
            {showConfirmSupportCaseScreen && (
                <ConfirmSupportCase supportCase={selectedSupportCase} organisation={organisation} />
            )}
            {showSelectSupportCaseScreen && (
                <SelectSupportCase supportCases={supportCases} setSelectedSupportCase={setSelectedSupportCase} />
            )}
            {isError && <NoSupportCaseFound code={code} onClick={goBack} />}
        </Page>
    );
};

function useHasFullAccess(
    selectedSupportCase: ISupportCaseInfo | null | undefined,
    organisation: IOrganisation | null | undefined,
) {
    const { hasPermission } = useHasPermission();

    return useMemo(
        () =>
            hasPermission([PermissionKeys.SUPPORT_CASES_READ, PermissionKeys.SUPPORT_CASE_READ]) &&
            (window.carity.environment.shareSupportCases ||
                selectedSupportCase?.organisations.find((org) => org.id === organisation?.id)),
        [hasPermission, selectedSupportCase, organisation],
    );
}

function useAutoSelectFirstSupportCase(
    supportCases: ISupportCaseInfo[] | undefined,
    organisation: IOrganisation | undefined,
    setSelectedSupportCase: (supportCase: ISupportCaseInfo | null) => void,
) {
    useEffect(() => {
        if (supportCases && organisation) {
            if (supportCases.length === 1) {
                setSelectedSupportCase(supportCases[0]);
            } else {
                const unconfirmedSupportCase = supportCases.find(({ isUnconfirmed }) => isUnconfirmed);
                if (unconfirmedSupportCase) {
                    return setSelectedSupportCase(unconfirmedSupportCase);
                }
                const selectedOrganisationSupportCases = supportCases.filter(({ organisations }) =>
                    organisations.some(({ id }) => id === organisation.id),
                );
                if (selectedOrganisationSupportCases.length >= 1) {
                    setSelectedSupportCase(selectedOrganisationSupportCases[0]);
                } else {
                    setSelectedSupportCase(null);
                }
            }
        }
    }, [supportCases, organisation]);
}

function useSupportCaseQueryParams(): { caseId: string; code: string; nationalSecurityNumber: string } {
    const location = useLocation();
    const caseId = qs.parse(location.search, { ignoreQueryPrefix: true }).case as string;
    const code = qs.parse(location.search, { ignoreQueryPrefix: true }).code as string;
    const nationalSecurityNumber = qs.parse(location.search, { ignoreQueryPrefix: true })
        .nationalSecurityNumber as string;
    return { caseId, code, nationalSecurityNumber };
}
