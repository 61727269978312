export const supportTranslationsNL = {
    showOnlyMySupportCases: 'Toon enkel mijn dossiers',
    addSupportee: 'Voeg hulpvrager toe',
    invalidNationalSecurityNumber: 'Het rijksregisternummer is niet correct',
    addSupportPeriod: 'Voeg hulpperiode toe',
    allOrganisationsAccess: 'Geef alle organisaties toegang',
    allOrganisationsHaveAccess: 'Alle organisaties hebben toegang tot dit hulpdossier.',
    anonymousLabel: 'Anoniem',
    card: 'Kaart',
    cards: 'Kaarten',
    multipleCards: 'Meerdere kaarten',
    caseNumberLabel: 'Hulpdossier',
    cases: 'Dossiers',
    child: 'Kind',
    codeConflictError: 'Kaart wordt al gebruikt bij één van de gekoppelde organisaties voor dit hulpdossier.',
    codeNotFoundError: 'Onjuiste code',
    commentDeleteWarningText: 'Bent u zeker dat u deze commentaar wenst te verwijderen uit het hulpdossier?',
    commentDeleteWarningTitle: 'Verwijder commentaar',
    createCardSuccess: 'Kaart succesvol aangemaakt.',
    createCardError: 'Er is een probleem opgetreden tijdens het aanmaken van de nieuwe kaart',
    createCommentError: 'Er is een probleem opgetreden tijdens het toevoegen van commentaar',
    createSupporteeSuccess: 'Hulpvrager succesvol aangemaakt.',
    createSupporteeError: 'Er is een probleem opgetreden tijdens het aanmaken van de hulpvrager.',
    createSupportCaseSuccess: 'Hulpdossier succesvol aangemaakt.',
    createSupportCaseError: 'Er is een probleem opgetreden tijdens het aanmaken van het hulpdossier.',
    createSupportPeriodSuccess: 'Hulpperiode succesvol aangemaakt',
    createSupportPeriodError: 'Er is een probleem opgetreden tijdens het aanmaken van de hulpperiode.',
    dateOfBirthLabel: 'Geboortedatum',
    deleteSupporteeSuccess: 'Hulpvrager succesvol verwijderd.',
    deleteSupporteeError: 'Er is een probleem opgetreden tijdens het verwijderen van de hulpvrager.',
    deleteSupportCaseSuccess: 'Hulpdossier succesvol verwijderd.',
    deleteSupportCaseError: 'Er is een probleem opgetreden tijdens het verwijderen van het hulpdossier.',
    mergeSupportCaseSuccess: 'Hulpdossiers succesvol samengevoegd.',
    mergeSupportCaseError: 'Er is een probleem opgetreden tijdens het samenvoegen van de hulpdossiers.',
    deleteSupportPeriodSuccess: 'Hulpperiode succesvol verwijderd.',
    deleteSupportPeriodError: 'Er is een probleem opgetreden tijdens het verwijderen van de hulpperiode',
    dependents: 'Afhankelijke personen',
    details: 'Details',
    disconnectCard: 'Loskoppelen kaart',
    disconnectCardWarningText: 'Bent u zeker dat u de kaart wenst te ontkoppelen van het hulpdossier?',
    disconnectCardWarningTitle: 'Loskoppelen kaart',
    downloadCard: 'Download kaart',
    editResidence: 'Aanpassen thuissituatie',
    edited: 'bewerkt',
    editContactInformation: 'Aanpassen contactinformatie',
    editFoodRestriction: 'Aanpassen voedselrestricties',
    editSupportee: 'Aanpassen hulpvrager',
    editOrganisationPermissions: 'Aanpassen authorisaties van organisaties',
    emptyStateAddress: 'Er is geen adres beschikbaar.',
    emptyStateContacts: 'Er is geen contactinformatie beschikbaar.',
    endBeforeStartDateError: 'De einddatum moet later zijn dan de begingdatum.',
    endDateInvalid: 'De einddatum is ongeldig',
    endDateLabel: 'Einddatum',
    endDateRequired: 'Einddatum is verplicht.',
    dateRequired: 'Datum is verplicht.',
    existingCard: 'Bestaande kaart',
    head_of_household: 'Gezinshoofd',
    co_resident: 'Medebewoner',
    preferredOrganisation: 'Voorkeursorganisatie',
    relation: 'Relatie',
    relationLabel: 'Relatie binnen hulpdossier ',
    relative: 'Familielid',
    startDateInvalid: 'De begindatum is ongeldig',
    startDateLabel: 'Begindatum',
    startDateRequired: 'Begindatum is verplicht.',
    supporteeAdd: 'Voeg hulpvrager toe',
    supporteeDeleteWarningText: 'Bent u zeker dat u deze hulpvrager wenst te verwijderen uit het hulpdossier?',
    supporteeDeleteWarningTitle: 'Verwijder hulpvrager',
    supportPeriodDeleteWaringText: 'Bent u zeker dat u deze hulpperiode wenst te verwijderen?',
    supportPeriodDeleteWaringTitle: 'Verwijder hulpperiode',
    female: 'Vrouwelijk',
    firstFiveListedBelow: 'De eerste vijf zijn hieronder zichtbaar',
    firstNameLabel: 'Voornaam',
    genderLabel: 'Geslacht',
    getCommentsError: 'Er is een probleem opgetreden bij het ophalen van de commentaar',
    getRegistrationsError: 'Er is een probleem opgetreden bij het ophalen van de hulpregistraties.',
    getSupportCaseError: 'Er is een probleem opgetreden bij het ophalen van het hulpdossier.',
    getSupportCasesError: 'Er is een probleem opgetreden bij het ophalen van de hulpdossiers.',
    getSupportCaseDuplicatesError: 'Er is een probleem opgetreden bij het ophalen van de dubbels.',
    getSupportPeriodsError: 'Er is een probleem opgetreden bij het ophalen van de hulpperiodes.',
    languageLabel: 'Taal',
    lastNameLabel: 'Naam',
    dependencyPercentage: 'Percentage ten laste',
    minDependencyPercentage: 'Minimum 0',
    maxDependencyPercentage: 'Maximum 100',
    dependency: 'ten laste',
    linkCard: 'Koppel kaart',
    linkCardSuccess: 'Kaart succesvol gekoppeld.',
    linkCardError: 'Er is een probleem opgetreden bij het koppelen van de kaart aan het hulpdossier.',
    male: 'Mannelijk',
    nationalityLabel: 'Nationaliteit',
    nationalSecurityNumberLabel: 'Rijksregisternummer',
    newCard: 'Nieuwe kaart',
    newSupportCase: 'Nieuw hulpdossier',
    not_applicable: 'Niet van toepassing',
    numberOfSupportees: 'Aantal hulpvragers',
    organisationsWithAccess: 'Organisaties die toegang hebben tot dit hulpdossier',
    other: 'Andere',
    partner: 'Partner',
    possibleDoubles_one: 'Het systeem vond <0>{{count}} mogelijke dubbel</0> voor deze persoon.',
    possibleDoubles_other: 'Het systeem vond <0>{{count}} mogelijke dubbels</0> voor deze persoon.',
    possibleAddressDoubles_one: 'Het systeem vond <0>{{count}} mogelijke dubbel</0> voor dit adres.',
    possibleAddressDoubles_other: 'Het systeem vond <0>{{count}} mogelijke dubbels</0> voor dit adres.',
    primaryContact: 'Primair contact',
    primarySupportee: 'Primaire hulpvrager',
    removeAccessText: 'zal niet langer toegang hebben tot dit hulpdossier.',
    removeAccessTitle: 'Verwijder toegang voor organisatie',
    systemFound: 'Het systeem vond',
    updateResidenceSuccess: 'Thuissituatie succesvol aangepast.',
    updateResidenceError: 'Er is een probleem opgetreden bij het aanpassen van de thuissituatie.',
    updateContactsSuccess: 'Contacten succesvol aangepast.',
    updateContactsError: 'Er is een probleem opgetreden bij het aanpassen van de contacten.',
    updateFoodRestrictionsSuccess: 'Voedselrestricties succesvol aangepast.',
    updateFoodRestrictionsError: 'Er is een probleem opgetreden bij het aanpassen van de voedselrestricties.',
    emptyStateFoodRestrictions: 'Er zijn geen voedselrestricties.',
    updateCommentError: 'Er is een probleem opgetreden bij het aanpassen van de commentaar.',
    updateSupporteeSuccess: 'Hulpvrager succesvol aangepast.',
    updateSupporteeError: 'Er is een probleem opgetreden bij het aanpassen van de hulpvrager.',
    updateSupportCaseSuccess: 'Hulpdossier succesvol aangepast.',
    updateSupportCaseError: 'Er is een probleem opgetreden bij het aanpassen van het hulpdossier.',
    updateSupportPeriodSuccess: 'Hulpperiode succesvol aangepast.',
    updateSupportPeriodError: 'Er is een probleem opgetreden bij het aanpassen van de hulpperiode.',
    supportCase: 'Hulpdossier',
    supportCaseDeleteWarningText: 'Bent u zeker dat u dit hulpdossier wenst te verwijderen?',
    supportCaseDeleteWarningTitle: 'Verwijder hulpdossier',
    supportCaseDoublesWarningText:
        'Het systeem heeft mogelijk dubbels gevonden. Bent u zeker dat u dit hulpdossier wenst aan te maken?',
    supportCaseDoublesWarningTitle_one: '{{count}} dubbel gevonden',
    supportCaseDoublesWarningTitle_other: '{{count}} dubbels gevonden',
    supportCases: 'Hulpdossiers',
    supportPeriods: 'Hulpperiodes',
    supportee: 'Hulpvrager',
    supportees: 'Hulpvragers',
    Email: 'Emailadres',
    Phone: 'Telefoonnummer',
    referrerContact: 'Contactpersoon',
    newContact: 'Nieuw contactpersoon',
    contactEmpty: 'Ten minste 1 veld van contact moet ingevuld zijn.',
    referralOrganisationRequired: 'De doorverwijzer is verplicht bij het aanmaken van een nieuw contactpersoon',
    residence: 'Thuissituatie',
    livingSituation: 'Woonsituatie',
    familySituation: 'Gezinssituatie',
    emptyStateLivingSituation: 'Er is geen woonsituatie gekend.',
    emptyStateFamilySituation: 'Er is geen gezinssituatie gekend.',
    showDuplicates: 'Toon dubbels',
    duplicates: 'Dubbels gevonden',
    duplicatesMessage_one: 'Er werd voor dit dossier {{count}} dubbel gevonden.',
    duplicatesMessage_other: 'Er werden voor dit dossier {{count}} dubbels gevonden.',
    markAsNoDuplicate: 'Markeer als geen dubbel',
    markAsNoDuplicateConfirmation:
        'Bent u zeker dat u dit dossier wil markeren als geen dubbel? Deze actie kan niet ongedaan gemaakt worden.',
    lastSupportPeriods: 'Laatste hulpperiodes',
    inactive: 'Inactief',

    reason: 'Reden',
    SUPPORTEE: 'o.b.v. persoon',
    ADDRESS: 'o.b.v. adresgegevens',

    mergeAndKeepInfo: 'Samenvoegen en deze gegevens behouden',
    merge: 'Samenvoegen',
    mergeConfirmation: 'Bent u zeker dat u deze dossiers wil samenvoegen? Deze actie kan niet ongedaan gemaakt worden.',

    SINGLE: 'Alleenstaande',
    SINGLE_WITH_CHILDREN: 'Alleenstaande met kinderen',
    WITH_PARTNER: 'Samenwonend met partner',
    WITH_PARTNER_AND_CHILDREN: 'Samenwonend met partner en kinderen',
    LIVING_WITH_PARENTS: 'Inwonend bij ouders',
    LIVING_WITH_ACQUAINTANCE: 'Inwonend bij kennissen / vrienden',

    HOMEOWNER: 'Eigenaar',
    PRIVATE_TENANT: 'Privaat huurder',
    SOCIAL_TENANT: 'Sociaal huurder',
    LIVING_WITH_FAMILY_OR_ACQUAINTANCE: 'Inwonend bij familie of kennis',
    HOMELESS: 'Dak/Thuisloos',
    COHOUSING: 'Cohousing',
    CAMPINGS: 'Camping',
    CARE_HOTEL: 'Zorghotel',

    attributes: 'Attributen',

    frequencyLabel: 'Frequentie',
    WHEN_POSSIBLE: 'Indien mogelijk',
    ONCE_A_WEEK: '1 x per week',
    ONCE_EVERY_TWO_WEEKS: '1 x om de 2 weken',
    ONCE_EVERY_THREE_WEEKS: '1 x om de 3 weken',
    ONCE_A_MONTH: '1 x per maand',

    currentSupportCase: 'Huidig dossier',
    duplicateSupportCase: 'Dubbel dossier',

    intakes: 'Intakes',
    checkAndNext: 'Controleer zonder doorverwijzing',
    checkAndSkip: 'Controleer met doorverwijzing',
    intakeStep: {
        intro: 'OCMW',
        income: 'Inkomsten',
        expenses: 'Uitgaven',
        completion: 'Resultaat',
    },
    useDataLastIntake: 'Inkomsten, uitgaven en opmerkingen uit laatste intake overnemen.',
    addIntake: 'Intake starten',
    DRAFT: 'In uitvoering',
    COMPLETE: 'Voltooid',
    income: 'Inkomsten',
    newIntake: 'Nieuwe intake',
    incomeHelpText: 'Vul hieronder het bedrag per maand in.',
    expenses: 'Uitgaven',
    expensesHelpText: 'Vul hieronder het bedrag per maand in.',
    lumpSumExpenses: 'Forfaitaire uitgaven',
    financialResearch: 'Financieel onderzoek',
    financialResult: 'Resultaat',
    createSupportPeriod: 'Hulpperiode aanmaken?',
    supportPeriod: 'Hulpperiode',
    supportPeriodWithSupportTypes: 'Hulpperiode {{supportType}}',
    supportTypesAdd: 'Voeg hulptype toe',
    creditLabel: 'Krediet',
    WEEK: 'Wekelijks',
    MONTH: 'Maandelijks',
    supportTypeIdMustBeUnique: 'De lijst van toegekende hulptypes moet uniek zijn.',
    creditFrequencyMustBeFilledIn: 'De frequentie moet ingevuld zijn indien een krediet wordt toegekent.',
    comments: 'Opmerkingen',
    moreInfo: 'Meer informatie',
    limit: 'Grens',
    externalIntakeResult: 'Check OCMW',
    externalIntake: {
        true: 'Actief traject',
        false: 'Geen actief traject',
    },
    externalIntakeError:
        'Er is iets misgelopen bij de OCMW check. Er kan niet geverifiëerd worden of er een actief traject is bij het OCMW.',

    intakeWarningTitle: 'Opgelet',
    intakeWarningPart1:
        'Om een juiste berekening te doen is een rijksregisternummer, geslacht en geboortedatum nodig voor elk gezinslid. Deze ontbreekt voor: ',
    intakeWarningPart2: "Je kan alsnog de intake starten door op 'controleer' te klikken.",
    noAccessToSupportCase: 'Geen toegang tot dit dossier',
};
