import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { useShowDrawer } from '../../../shared';
import { AppTitle } from '../app-title/app-title.component';
import { Navigation } from '../navigation/navigation';

export function Header() {
    const { showDrawer, setShowDrawer, hasDrawer } = useShowDrawer();

    return (
        <>
            <Box component="div" sx={{ height: 55 }} />
            <AppBar>
                <Toolbar>
                    {hasDrawer && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => setShowDrawer(!showDrawer)}
                            sx={{ mx: 2, display: { xl: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <AppTitle />
                    <Navigation />
                </Toolbar>
            </AppBar>
        </>
    );
}
