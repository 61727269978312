export const commonTranslationsNL = {
    addBtn: 'Voeg toe',
    addressLabel: 'Adres',
    cancel: 'Annuleer',
    cityLabel: 'Stad',
    confirm: 'bevestig',
    comments: 'Commentaar',
    contactInformation: 'Contactinformatie',
    contactLabel: 'Contact',
    contactsAdd: 'Voeg contact toe',
    foodRestrictionAdd: 'Voeg voedselrestrictie toe',
    countryLabel: 'Land',
    'creation-successful': 'Succesvol aangemaakt',
    BOOLEAN: 'Ja/nee vraag',
    AMOUNT: 'Aantal',
    data: 'Gegevens',
    date: 'Datum',
    delete: 'Verwijder',
    edit: 'Pas aan',
    goBack: 'Ga terug naar de vorige pagina',
    nameLabel: 'Naam',
    noInfo: 'Geen gegevens beschikbaar.',
    numberLabel: 'Nummer',
    boxLabel: 'Busnummer',
    ok: 'ok',
    organisation: 'Organisatie',
    pageNotFoundErr: 'Deze pagina kon niet worden gevonden.',
    postalCodeLabel: 'Postcode',
    save: 'Opslaan',
    search: 'Zoek',
    'something-went-wrong': 'Er ging iets mis',
    streetLabel: 'Straat',
    'update-successful': 'Succesvol aangepast',
    logOut: 'Uitloggen',
    forbiddenTitle: 'Verboden toegang',
    forbiddenText: 'Je hebt onvoldoende rechten voor het uitvoeren van de laatste actie.',
    active: 'Actief',
    lastVisit: 'Laatste bezoek {{supportType}}',
    notFound: 'Niet gevonden',
    notActive: 'Niet Actief',
    notFoundTitle: 'Pagina niet gevonden',
    notFoundText: 'Oeps... Deze pagina bestaat niet.',
    backButton: 'Ga terug naar vorige pagina',
    unknown: 'Onbekend',
    createdDate: 'Aanmaakdatum',
    createdBy: 'Aangemaakt door',
    tryAgainLater:
        'Probeer later nog eens, indien deze foutmelding zich opnieuw voordoet: contacteer de verantwoordelijke.',
    previous: 'Vorige',
    next: 'Volgende',
    complete: 'Voltooi',
    yes: 'Ja',
    no: 'Nee',
    general: 'Algemeen',
    contact: 'Contact',
    noRows: 'Geen gegevens gevonden.',
    testingEnvironment: 'Dit is de testomgeving.',
};
