export enum PermissionKeys {
    ORGANISATIONS_WRITE = 'organisations-write',
    ORGANISATIONS_READ = 'organisations-read',
    ORGANISATION_TYPES_WRITE = 'organisation-types-write',
    ORGANISATION_TYPES_READ = 'organisation-types-read',
    SUPPORT_TYPES_WRITE = 'support-types-write',
    SUPPORT_TYPES_READ = 'support-types-read',
    SUPPORT_CASES_WRITE = 'support-cases-write',
    SUPPORT_CASES_READ = 'support-cases-read',
    SUPPORT_CASE_READ = 'support-case-read',
    REGISTRATIONS_READ = 'registrations-read',
    REGISTRATIONS_WRITE = 'registrations-write',
    CARDS_WRITE = 'cards-write',
    USERS_READ = 'users-read',
    USERS_WRITE = 'users-write',
    SUPPORT_CASE_COMMENTS_WRITE = 'support-case-comments-write',
    SUPPORT_CASE_COMMENTS_READ = 'support-case-comments-read',
    ATTRIBUTES_WRITE = 'attributes-write',
    REFERRAL_ORGANISATIONS_WRITE = 'referral-organisations-write',
    SHOP_APP = 'shop-app',
    SUPPORT_TYPE_FREQUENCIES_WRITE = 'support-type-frequencies-write',
    INCOME_TYPES_READ = 'income-types-read',
    INCOME_TYPES_WRITE = 'income-types-write',
    EXPENSE_TYPES_READ = 'expense-types-read',
    EXPENSE_TYPES_WRITE = 'expense-types-write',
    INTAKES_READ = 'intakes-read',
    INTAKES_WRITE = 'intakes-write',
    SUPPORT_PERIODS_WRITE = 'support-periods-write',
    FOOD_RESTRICTIONS_READ = 'food-restrictions-read',
    FOOD_RESTRICTIONS_WRITE = 'food-restrictions-write',
    REPORTS_READ = 'reports-read',
}
