import { CalendarTodayTwoTone, DeleteOutline, Edit } from '@mui/icons-material';
import { Button, IconButton, Stack } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ConfirmModal,
    formatDateString,
    IOrganisation,
    IParams,
    IReferralOrganisation,
    IReferrerContact,
    ISupportPeriod,
    ISupportTypeInfo,
    Page,
    PermissionKeys,
    SortOrder,
    Table,
    useHasPermission,
    useQueryParams,
    Widget,
} from '../../../shared';
import { DuplicatesWarning, SaveSupportPeriodModal, SupportCaseTitle } from '../../components';
import { referrerContactFormatter } from '../../formatters';
import { useDeleteSupportPeriod, useGetSupportCase, useGetSupportPeriods } from '../../hooks';
import { Frequency } from '../../enums';

export const SupportPeriodsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_PERIODS_WRITE);
    const hasIntakesWritePermission = hasPermission(PermissionKeys.INTAKES_WRITE);
    const { id } = useParams<keyof IParams>() as IParams;
    const { page, pageSize, setPage, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'startDate', sort: 'desc' }],
    });

    const [supportPeriodModal, setSupportPeriodModal] = useState<{
        show: boolean;
        supportPeriod: ISupportPeriod | null;
    }>({
        show: false,
        supportPeriod: null,
    });

    const { data: supportCase } = useGetSupportCase(id);
    const { data: supportPeriods, isPending: supportPeriodsIsPending } = useGetSupportPeriods(id, {
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
    });

    const { mutateAsync: deleteSupportPeriod } = useDeleteSupportPeriod();

    const columns: GridColDef[] = [
        {
            field: 'startDate',
            headerName: t('startDateLabel'),
            minWidth: 100,
            flex: 1,
            valueFormatter: (value: string) => formatDateString(value),
        },
        {
            field: 'endDate',
            headerName: t('endDateLabel'),
            minWidth: 100,
            flex: 1,
            valueFormatter: (value: string) => formatDateString(value),
        },
        {
            field: 'supportTypesInfo',
            headerName: t('supportTypes'),
            minWidth: 200,
            flex: 2,
            sortable: false,
            valueFormatter: (value: ISupportTypeInfo[]) =>
                value
                    ?.map(({ supportType, credit, frequency }) =>
                        supportType
                            ? `${supportType.name}${credit && frequency ? ` (${t(frequency)} €${credit.toString().replace('.', ',')})` : ''}`
                            : '',
                    )
                    .join(', '),
        },
        {
            field: 'frequency',
            headerName: t('frequencyLabel'),
            minWidth: 150,
            flex: 1,
            valueFormatter: (value: Frequency) => t(value),
        },
        {
            field: 'organisation',
            headerName: t('preferredOrganisation'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (value: IOrganisation) => value?.name,
        },
        {
            field: 'referralOrganisation',
            headerName: t('referralOrganisation'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (value: IReferralOrganisation) => value?.name,
        },
        {
            field: 'contact',
            headerName: t('referrerContact'),
            minWidth: 200,
            flex: 2,
            valueFormatter: (value: IReferrerContact) => referrerContactFormatter(value),
        },
        ...(hasWritePermission
            ? ([
                  {
                      field: 'edit',
                      headerName: ' ',
                      sortable: false,
                      minWidth: 100,
                      align: 'right',
                      flex: 1,
                      renderCell: ({ row, id: cellId }: GridCellParams<ISupportPeriod>) => (
                          <>
                              <IconButton
                                  size="large"
                                  onClick={() => setSupportPeriodModal({ show: true, supportPeriod: row })}
                                  data-testid={`edit-${cellId}`}
                              >
                                  <Edit />
                              </IconButton>
                              <ConfirmModal
                                  onConfirm={() =>
                                      row.id &&
                                      deleteSupportPeriod({
                                          supportCase: id,
                                          supportPeriod: row.id,
                                      })
                                  }
                                  button={
                                      <IconButton aria-label="delete" size="large" data-testid={`delete-${cellId}`}>
                                          <DeleteOutline />
                                      </IconButton>
                                  }
                                  title={t('supportPeriodDeleteWaringTitle')}
                                  text={
                                      <>
                                          {t('supportPeriodDeleteWaringText')}
                                          <br />
                                          <b>
                                              {formatDateString(row.startDate)} - {formatDateString(row.endDate)}
                                              {'\n'}
                                              {row.supportTypesInfo
                                                  ?.map(({ supportType }) => supportType?.name || '')
                                                  .join(', ')}
                                          </b>
                                      </>
                                  }
                              />
                          </>
                      ),
                  },
              ] as GridColDef[])
            : ([] as GridColDef[])),
    ];

    return (
        <Page onBack={() => navigate(-1)} title={supportCase && <SupportCaseTitle supportCase={supportCase} />}>
            <DuplicatesWarning supportCaseId={id} />
            <Widget
                icon={<CalendarTodayTwoTone color="primary" />}
                title={t('supportPeriods')}
                hasTable
                actions={
                    <Stack spacing={2} direction="row">
                        {hasWritePermission && (
                            <Button
                                variant="outlined"
                                color="primary"
                                data-testid="add-support-period"
                                onClick={() => setSupportPeriodModal({ show: true, supportPeriod: null })}
                            >
                                + {t('addSupportPeriod')}
                            </Button>
                        )}
                        {hasIntakesWritePermission && (
                            <Button variant="outlined" color="primary" onClick={() => navigate('../intakes/new/intro')}>
                                + {t('addIntake')}
                            </Button>
                        )}
                    </Stack>
                }
            >
                <Table
                    rows={supportPeriods?.data || []}
                    columns={columns}
                    rowCount={supportPeriods?.pagination.size || 0}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    loading={supportPeriodsIsPending}
                    sortModel={sort}
                    onSortChange={setSort}
                />
            </Widget>
            <SaveSupportPeriodModal
                show={supportPeriodModal.show}
                supportCase={id}
                supportPeriod={supportPeriodModal.supportPeriod}
                handleClose={() => {
                    setSupportPeriodModal({ show: false, supportPeriod: null });
                }}
            />
        </Page>
    );
};
