import { DeleteOutline, Edit } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    ConfirmModal,
    ISupportType,
    Page,
    SortOrder,
    Table,
    useDebouncedState,
    useGetSupportTypes,
    useQueryParamsContext,
} from '../../../shared';
import { SearchInput } from '../../../shared/components/search/search.component';
import { useDeleteSupportType } from '../../hooks';

export function SupportTypesPage() {
    const { t } = useTranslation();
    const {
        queryParams: { page, pageSize, sort },
        setPage,
        setPageSize,
        setSort,
    } = useQueryParamsContext('support-types', {
        sort: [{ field: 'name', sort: 'asc' }],
    });

    const [debouncedSearch, search, setSearch] = useDebouncedState('');

    const { isPending, data: supportTypes } = useGetSupportTypes({
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const { mutate: mutateDelete } = useDeleteSupportType();

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('nameLabel'), minWidth: 150, flex: 1 },
        {
            field: 'hasCredit',
            headerName: t('hasCreditLabel'),
            flex: 1,
            valueFormatter: (value: boolean) => (value ? t('yes') : t('no')),
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams<ISupportType>) => (
                <>
                    <IconButton component={Link} to={`/admin/support-types/${id}/edit`}>
                        <Edit />
                    </IconButton>
                    <ConfirmModal
                        onConfirm={() => {
                            mutateDelete(id);
                        }}
                        button={
                            <IconButton aria-label="delete">
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('supportTypeDeleteWarningTitle')}
                        text={t('supportTypeDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('supportTypes')}
            actions={[
                <SearchInput value={search} setValue={setSearch} />,
                <Button
                    component={Link}
                    to="/admin/support-types/new"
                    color="primary"
                    variant="contained"
                    data-testid="newSupportType"
                >
                    {t('newSupportType')}
                </Button>,
            ]}
        >
            <Table
                rows={supportTypes?.data || []}
                columns={columns}
                rowCount={supportTypes?.pagination.size || 0}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                loading={isPending}
                sortModel={sort}
                onSortChange={setSort}
            />
        </Page>
    );
}
