import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardTwoTone } from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';
import React, { FC, ReactNode, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { Widget } from '../../../shared';
import { useRegistrationQrCodeSchema } from '../../validators';

export const StartRegistrationQrCode: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const form = useForm<{ code: string }>({
        resolver: yupResolver(useRegistrationQrCodeSchema()),
    });

    const onSubmit = useCallback(
        (data: { code: string }) => {
            navigate(`/registration/info?code=${data.code}`);
        },
        [navigate],
    );

    return (
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
            <Widget sx={{ alignItems: 'center', textAlign: 'center', p: 4 }}>
                <KeyboardTwoTone sx={{ fontSize: 80 }} color="primary" />
                <Typography variant="h6">{t('typeCode')}</Typography>
                <Box sx={{ width: 350, alignSelf: 'center', mt: 1, mb: 2 }}>
                    <Controller
                        name={`code`}
                        control={form.control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <ReactInputMask mask="****-****" value={value} onChange={onChange}>
                                {
                                    (() => (
                                        <TextField
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                            id={`code-field`}
                                            placeholder={'XXXX-XXXX'}
                                            variant="outlined"
                                        />
                                    )) as unknown as ReactNode
                                }
                            </ReactInputMask>
                        )}
                    />
                </Box>
                <Box sx={{ width: 350, alignSelf: 'center' }}>
                    <Button type="submit" variant="contained" size="large" fullWidth>
                        {t('startRegistration')}
                    </Button>
                </Box>
            </Widget>
        </form>
    );
};
